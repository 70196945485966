import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

function Cookies() {
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Términos y Condiciones",
        }}
      />
      <main className="container">
        <section className="mt-10 w-10/12 space-y-5">
          <h6 className="text-xl text-exoticberry">
            POLÍTICA DE COOKIES PARA EL SITIO BOONE’S MÉXICO
          </h6>
          <p>
            El sitio web Boone’s (el “Sitio”) utiliza cookies. Las cookies son
            pequeños archivos de datos que el sitio almacena en su equipo o
            dispositivo móvil. Esta es una práctica habitual en los sitios web.
            Esta política explica cuándo y por qué se almacenan cookies en su
            equipo cuando usted visita el Sitio y cómo estas pueden ser
            bloqueadas y eliminadas.
          </p>
          <h6 className="text-xl text-exoticberry">
            ¿QUÉ SON LAS COOKIES Y CÓMO FUNCIONAN?
          </h6>
          <p>
            Una cookie es un archivo de texto que se almacena en su equipo o
            dispositivo móvil desde el servidor de un sitio web y sólo ese
            servidor es capaz de recuperar o leer el contenido de las cookies
            almacenadas. Cada cookie es única de acuerdo con su navegador. Cada
            cookie contiene cierta información anónima tal como un identificador
            único, el nombre del sitio y algunos dígitos y números. Las cookies
            pueden ser almacenadas por el sitio web que está visitando (cookies
            propias) o pueden ser instaladas por otros sitios web que dirigen el
            contenido de la página que está viendo (cookies de terceros). La
            mayoría de los sitios web que visita utiliza cookies con el fin de
            mejorar la experiencia del usuario al permitir que el sitio web lo
            recuerde, ya sea por la duración de su visita (cookie de sesión) o
            por la repetición en las visitas al sitio (cookie persistente). Las
            cookies realizan diferentes funciones, tales como permitir navegar
            por las páginas de manera eficiente, guardar sus preferencias, y en
            general mejorar la experiencia respecto de un sitio web. Las cookies
            permiten una interacción más rápida y eficiente entre el usuario y
            el sitio web. Si un sitio web no utiliza cookies, el sitio web
            pensará que usted es un visitante nuevo cada vez que entra a una
            nueva página dentro del sitio – por ejemplo, cuando introduce sus
            datos de acceso y cambia a otra página dentro del mismo sitio, no lo
            reconocerá y no será capaz de mantenerlo en la sesión iniciada.
          </p>
          <h6 className="text-xl text-exoticberry">
            EL USO DE COOKIES EN ESTE SITIO: SUS ELECCIONES
          </h6>
          <p>
            De acuerdo con la ley en México, es necesario contar con su
            consentimiento para el almacenamiento de cookies en su equipo o
            dispositivo móvil, salvo que las cookies sean estrictamente
            necesarias para ofrecerle nuestros servicios. Al entrar en nuestro
            Sitio después de que le sea presentada la notificación de que el
            Sitio utiliza cookies, usted estará otorgando su consentimiento para
            el uso de cookies durante su visita. A menos de que la cookie sea
            estrictamente necesaria, es posible modificar las preferencias de su
            navegador para notificarle cuando se le envían cookies o también es
            posible rechazar todas las cookies por completo. La función de ayuda
            dentro de su navegador debe decirle cómo puede modificar estas
            preferencias. También puede eliminar las cookies que ya han sido
            almacenadas, sin embargo, recuerde que si usted no está de acuerdo
            con nuestras cookies de funcionalidad, partes de nuestro sitio web
            podrían no funcionar. De forma alternativa, es posible que desee
            visitar el sitio web www.aboutcookies.org, mismo que contiene mayor
            información respecto de cómo deshabilitar las cookies en una amplia
            variedad de navegadores.
          </p>
          <h6 className="text-xl text-exoticberry">
            QUE COOKIES UTILIZAMOS Y POR QUÉ
          </h6>
          <p>
            Las cookies nos permiten identificar su dispositivo, o cuando se ha
            iniciado su sesión. Utilizamos las cookies que son estrictamente
            necesarias para que pueda navegar por el Sitio o para proporcionar
            ciertas funcionalidades básicas. Utilizamos cookies para mejorar la
            funcionalidad del sitio, por ejemplo, al tener almacenadas sus
            preferencias. También utilizamos cookies para ayudarnos a mejorar el
            rendimiento de nuestro Sitio y así ofrecerle una mejor experiencia
            de usuario. No vendemos la información recogida por las cookies, ni
            revelamos la información a terceros, salvo que esta información sea
            requerida por la ley. Las siguientes tablas explican cuales son las
            cookies que utilizamos en nuestro Sitio, el por qué los usamos y si
            son cookies de funcionalidad o de rendimiento. Tenga en cuenta que
            cualquier información recogida por las cookies de funcionalidad y de
            rendimiento en este sitio web se realiza de forma anónima. También
            informamos si una cookie es una cookie persistente o de sesión.
          </p>
          <h6 className="text-xl text-exoticberry">COOKIES DE FUNCIONALIDAD</h6>
          <p>
            Las cookies de funcionalidad registran información sobre las
            elecciones que usted ha hecho y nos permiten adaptar el sitio web
            para usted. Estas cookies permiten al sitio web recordar las
            decisiones que usted ha tomado (como sus datos de acceso) y la
            configuración de sus preferencias (por ejemplo, el tamaño del
            texto). Utilizamos una cookie de funcionalidad para almacenar la
            información que usted nos proporciona cuando usted nos confirma que
            tiene la edad legal para beber alcohol en su país. Esta cookie se
            denomina «Agegate» y se almacenará en su máquina por un periodo de
            14 días antes de que caduque. La utilización de esta cookie nos
            permite no preguntarle a su fecha de nacimiento cada vez que visite
            el Sitio. Estas cookies se pueden utilizar también para proporcionar
            servicios que usted ha solicitado, como ver un video o utilizar un
            mapa, que es para lo que las utilizamos en nuestro sitio web.
          </p>
          <h6 className="text-xl text-exoticberry">COOKIES DE RENDIMIENTO</h6>
          <p>
            Utilizamos cookies de «rendimiento» para analizar sus patrones de
            visita en nuestro Sitio y otras tendencias y estadísticas con
            respecto a su uso de nuestro sitio y los sitios que visitó antes
            (referencias). El uso de estas cookies nos ayuda a ofrecerle la
            mejor experiencia web para usted y para entender lo que nuestros
            lectores están buscando para poder producir más del contenido
            deseado. Si usted desea restringir o bloquear las cookies que se
            almacenan en su dispositivo, puede hacerlo a través de la
            configuración del navegador. No utilizamos cookies para rastrear sus
            actividades de navegación en Internet antes o después de visitar el
            sitio.
          </p>
        </section>
        <section className="my-10 space-y-10">
          <table className="table-auto text-center">
            <thead className="text-exoticberry">
              <tr>
                <th>Tipo</th>
                <th>Nombre</th>
                <th>Propósito</th>
                <th>Cookie Persistente / Sesión</th>
                <th>Información Almacenada</th>
                <th>Nombre del Dominio</th>
                <th>Tercero</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Google Analytics</td>
                <td>_ga</td>
                <td>
                  Asigna ID de usuario especial para el visitante, fecha y hora,
                  y la versión de Google de Google Analytics.
                </td>
                <td>Persistente</td>
                <td>Alfanumérico</td>
                <td>boones.mx</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto text-center">
            <thead className="text-exoticberry">
              <tr>
                <th>Tipo</th>
                <th>Nombre</th>
                <th>Propósito</th>
                <th>Cookie Persistente / Sesión</th>
                <th>Información Almacenada</th>
                <th>Nombre del Dominio</th>
                <th>Tercero</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Google Analytics</td>
                <td>_gat_UA-58043196-19</td>
                <td>
                  Esta cookie no almacena ninguna información de usuario,
                  simplemente se usa para limitar el número de solicitudes que
                  se tienen que hacer a doubleclick.net.
                </td>
                <td>Persistente</td>
                <td>Booleana</td>
                <td>boones.mx</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto text-center">
            <thead className="text-exoticberry">
              <tr>
                <th>Tipo</th>
                <th>Nombre</th>
                <th>Propósito</th>
                <th>Cookie Persistente / Sesión</th>
                <th>Información Almacenada</th>
                <th>Nombre del Dominio</th>
                <th>Tercero</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Verificación de edad</td>
                <td>agegate</td>
                <td>
                  Permite que los usuarios no utilicen el Agegate una vez
                  establecido.
                </td>
                <td>Persistente</td>
                <td>Booleana</td>
                <td>boones.mx</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto text-center">
            <thead className="text-exoticberry">
              <tr>
                <th>Tipo</th>
                <th>Nombre</th>
                <th>Propósito</th>
                <th>Cookie Persistente / Sesión</th>
                <th>Información Almacenada</th>
                <th>Nombre del Dominio</th>
                <th>Tercero</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Analytics catalizador de sitio</td>
                <td>gpv_p7</td>
                <td>
                  Almacena información sobre el enlace actual hace clic dentro
                  del sitio.
                </td>
                <td>Persistente</td>
                <td>URL</td>
                <td>boones.mx</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto text-center">
            <thead className="text-exoticberry">
              <tr>
                <th>Tipo</th>
                <th>Nombre</th>
                <th>Propósito</th>
                <th>Cookie Persistente / Sesión</th>
                <th>Información Almacenada</th>
                <th>Nombre del Dominio</th>
                <th>Tercero</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Funcionalidad CMS WordPress</td>
                <td>has_js</td>
                <td>
                  WordPress utiliza esta cookie para indicar si el navegador del
                  visitante ha habilitado JavaScript.
                </td>
                <td>Sesión</td>
                <td>Booleana</td>
                <td>boones.mx</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto text-center">
            <thead className="text-exoticberry">
              <tr>
                <th>Tipo</th>
                <th>Nombre</th>
                <th>Propósito</th>
                <th>Cookie Persistente / Sesión</th>
                <th>Información Almacenada</th>
                <th>Nombre del Dominio</th>
                <th>Tercero</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Analytics catalizador de sitio</td>
                <td>s_cc</td>
                <td>Determina si las cookies están habilitadas.</td>
                <td>Sesión</td>
                <td>Booleana</td>
                <td>boones.mx</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto text-center">
            <thead className="text-exoticberry">
              <tr>
                <th>Tipo</th>
                <th>Nombre</th>
                <th>Propósito</th>
                <th>Cookie Persistente / Sesión</th>
                <th>Información Almacenada</th>
                <th>Nombre del Dominio</th>
                <th>Tercero</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Analytics catalizador de sitio</td>
                <td>s_fid</td>
                <td>
                  Contiene un generado de forma aleatoria, de la identificación
                  única para los usuarios.
                </td>
                <td>Persistente</td>
                <td>Alfanumérico</td>
                <td>boones.mx</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto text-center">
            <thead className="text-exoticberry">
              <tr>
                <th>Tipo</th>
                <th>Nombre</th>
                <th>Propósito</th>
                <th>Cookie Persistente / Sesión</th>
                <th>Información Almacenada</th>
                <th>Nombre del Dominio</th>
                <th>Tercero</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Analytics catalizador de sitio</td>
                <td>s_nr</td>
                <td>
                  Almacena fecha de visita y si el visitante es nuevo o
                  recurrente.
                </td>
                <td>Persistente</td>
                <td>Alfanumérico</td>
                <td>boones.mx</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mt-10 w-10/12 space-y-5">
          <h6 className="text-xl text-exoticberry">
            CAMBIOS A ESTA POLÍTICA DE COOKIES
          </h6>
          <p>
            Si esta política de cookies cambia, la política actualizada incluirá
            una nueva fecha de vigencia, misma que será publicada en esta misma
            página. Por favor, revise regularmente esta pagina para mantenerse
            informado de cualquier cambio. La fecha efectiva de esta Política de
            Cookies es Enero 2016.
          </p>
        </section>
      </main>
    </Layout>
  );
}

export default Cookies;
